import React, { useState, useEffect, useContext } from 'react';
import { axiosUrl } from '../api/axios';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FaPlay, FaPause } from 'react-icons/fa';
import AuthContext from '../data/AuthContext';
import Smollplayer from '../Components/SmollPlayer/Smollplayer';
import { DataContext } from '../data/DataContext';

import './index.scss';

const Tracks = () => {
    const { albomId } = useParams();
    const [tracks, setTracks] = useState([]);
    const [sound, setSound] = useState([]);
    const [addsSound, setAddsSound] = useState([]);
    const [addAlbom, setAddAlbom] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user, userProf, userAddTracks, userAddAlboms, setIsBuy, isBuy } = useContext(AuthContext);
    const {isPlaying, setIsPlaying, corectTrack, setCorectTrack, setIsOpenBasket} = useContext(DataContext)
    const [displey, setDispley] = useState(false);
    const [displeyBuy, setDispleyBuy] = useState(false);

    useEffect(() => {
        const fetchTracks = async () => {
            try {
                const response = await axiosUrl.get(`/alboms/${albomId}/`)
                setTracks(response.data); 
                const soundFiles = response.data.trecks.map(test => test.audio_file);
                setSound(soundFiles)
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        fetchTracks();
    }, [albomId, displeyBuy, isBuy]);

    useEffect(() => {
        if (userProf) {
            const soundFiles = userProf.favorite_songs.map(test => test.title);
            const albomFiles = userProf.favorite_alboms.map(test => test.title);
            setAddsSound(soundFiles)
            setAddAlbom(albomFiles)
        }
    }, [userProf, isBuy, displeyBuy])

    const addTracks = (num) => {
        userAddTracks(num)
        // window.location.reload();
    }

    const handleClick = () => {
        setDispley(true)
    };
    const handleClickBuy = () => {
        setDispleyBuy(true);
        setIsBuy(true)
    };

    if (loading) {
        // Відображення загрузки, поки дані завантажуються
        return <main className='Missing'>
            <ul>
                Loading...
            </ul>
        </main>;
        }
    
    if (tracks.trecks.length === 0) {
        return (<main className='Track'>
            <ul>Пусто!</ul>
        </main>)
    }

    if (isPlaying ) {
        return (
           <main className='Track TrackPlayer'>
                <div className='Track_Header'>
                    <img className='Track_Cart_Albom_Img' key={tracks.trecks.id} src={tracks.photo} alt={`albom+${tracks.trecks.id}`} />
                    <div className='Track_Albom'>
                        <h1>{tracks.title}</h1>
                        <Link to={`/artists/${tracks.artist.id}`} key={tracks.artist.id}>
                            <img className='Track_Cart_Artist_Img' key={tracks.artist.id} src={tracks.artist.photo} alt={tracks.artist.id} />
                            <h3>{tracks.artist.title}</h3>
                        </Link>
                        {tracks.trecks.length <= 5 ? <p>В альбомі: {tracks.trecks.length} пісні!</p> : <p>В альбомі: {tracks.trecks.length} пісень!</p>}
                    </div>

                    {user ? 
                    (addAlbom.includes(tracks.title) ? <Link className='isBuyButton' onClick={() => {setIsOpenBasket(true)}}>У кошику!</Link> :
                    <Link className='buyButton' onClick={() => {userAddAlboms(tracks.id)}}>купити!</Link>) :
                    <>
                    {displeyBuy && <div style={{color:'#910805', "align-self":'end', "margin-left":"auto"}}>Log In</div>}
                    <Link className='buyButton' onClick={() => handleClickBuy()}>купити!</Link>
                    </>}

                </div>
                <ul>
                {tracks.trecks.map((track) => (
                    <li className='Track_Cart' key={`${track.title}+${track.id}`}>             
                        {corectTrack === track.audio_file ? 
                            (isPlaying ? 
                                <FaPause className='play' onClick={() => {setIsPlaying(false)}}/> :
                                <FaPlay className='play' onClick={() => {setCorectTrack(track.audio_file); setIsPlaying(true)}}/>                           
                             ) : <FaPlay className='play' onClick={() => {setCorectTrack(track.audio_file); setIsPlaying(true)}}/> }
                        <img className='Track_Cart_Img' key={track.id} src={tracks.photo} alt={track.id} />
                        <div className='data_title'>{track.title}</div>
                        <div className='data_track'>{track.date_request}</div>
                        {displey && <div style={{color:'#910805', "align-self":'center'}}>Log In</div>}
                        {user ? (addsSound.includes(track.title) ? 
                            <FontAwesomeIcon className='plus' icon={faCheck} /> : 
                            <FontAwesomeIcon className='plus' onClick={() => addTracks(track.id)} icon={faPlus} />)
                             : <FontAwesomeIcon className='plus' onClick={() => handleClick()} icon={faPlus} />}
                    </li>
                ))}
                </ul>
                    {corectTrack && <Smollplayer src={tracks.photo} soundList={sound} />}    
            </main>)}

    if (!isPlaying ) {
        return (
        <main className='Track'>
                <div className='Track_Header'>
                    <img className='Track_Cart_Albom_Img' key={tracks.trecks.id} src={tracks.photo} alt={`albom+${tracks.trecks.id}`} />
                    <div className='Track_Albom'>
                        <h1>{tracks.title}</h1>
                        <Link to={`/artists/${tracks.artist.id}`} key={tracks.artist.id}>
                            <img className='Track_Cart_Artist_Img' key={tracks.artist.id} src={tracks.artist.photo} alt={tracks.artist.id} />
                            <h3>{tracks.artist.title}</h3>
                        </Link>
                        {tracks.trecks.length < 5 && tracks.trecks.length > 1 ? <p>В альбомі: {tracks.trecks.length} пісні!</p> : <p>В альбомі: {tracks.trecks.length} пісень!</p>}
                    </div>
                    {user ? 
                    (addAlbom.includes(tracks.title) ? <Link className='isBuyButton' onClick={() => {setIsOpenBasket(true)}}>У кошику!</Link> :
                    <Link className='buyButton' onClick={() => {userAddAlboms(tracks.id)}}>купити!</Link>) :
                    <>
                    {displeyBuy && <div style={{color:'#910805', "align-self":'end', "margin-left":"auto"}}>Log In</div>}
                    <Link className='buyButton' onClick={() => handleClickBuy()}>купити!</Link>
                    </>}
                </div>
                <ul>
                {tracks.trecks.map((track) => (
                    <li className='Track_Cart' key={`${track.title}+${track.id}`}>             
                        {corectTrack === track.audio_file ? 
                            (isPlaying ? 
                                <FaPause className='play' onClick={() => { setIsPlaying(false)}}/> :
                                <FaPlay className='play' onClick={() => {setCorectTrack(track.audio_file); setIsPlaying(true)}}/>                           
                            ) : <FaPlay className='play' onClick={() => {setCorectTrack(track.audio_file); setIsPlaying(true)}}/> }
                        <img className='Track_Cart_Img' key={track.id} src={tracks.photo} alt={track.id} />
                        <div className='data_title'>{track.title}</div>
                        <div className='data_track'>{track.date_request}</div>
                        {displey && <div style={{color:'#910805', "align-self":'center'}}>Log In</div>}
                        {user ? (addsSound.includes(track.title) ? 
                            <FontAwesomeIcon className='plus' icon={faCheck} /> : 
                            <FontAwesomeIcon className='plus' onClick={() => addTracks(track.id)} icon={faPlus} />)
                            : <FontAwesomeIcon className='plus' onClick={() => handleClick()} icon={faPlus} />}
                    </li>
                ))}
                </ul>
                    {corectTrack && <Smollplayer soundList={sound} />}    
        </main>)}
    }

export default Tracks